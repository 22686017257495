html {
  margin: 0;
  height: 100%;
}

table.table {
  background: none;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(#d0bc9e 3px, transparent 3px),
    radial-gradient(#d0bc9e 3px, transparent 3px), #c6b294;
  background-size: 50px 50px, 50px 50px, 100% 3px;
  background-position: 25px 25px, 0px 0px, 0px 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
