.Button {
  background-color: #f6f6df;
  border: none;
  border-radius: 1em;
  padding: 0.5em 1.5em;
  margin: 1em;

  color: #8a7d6b;
  font-weight: bolder;
  cursor: pointer;
}

.Button:active {
  background-color: #06b4aa;
}

.Button:disabled {
  background-color: rgb(174, 174, 174);
  color: #f6f6df;
  cursor: not-allowed;
}
