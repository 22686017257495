.Note {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;

  &.x {
    background-image: url("rest.png");
  }

  &.r {
    background-image: url("./repeat.png");
  }

  &.g {
    background-image: url("lowg.png");
  }

  &.a {
    background-image: url("lowa.png");
  }

  &.b {
    background-image: url("lowb.png");
  }

  &.c {
    background-image: url("lowc.png");
  }

  &.d {
    background-image: url("lowd.png");
  }

  &.e {
    background-image: url("lowe.png");
  }

  &.f {
    background-image: url("lowf.png");
  }

  &.G {
    background-image: url("hig.png");
  }

  &.A {
    background-image: url("hia.png");
  }

  &.B {
    background-image: url("hib.png");
  }

  &.C {
    background-image: url("hic.png");
  }

  &.D {
    background-image: url("hid.png");
  }

  &.E {
    background-image: url("hie.png");
  }
}

/* Desktop */
@media only screen and (min-device-width: 1200px) {
  .Note {
    width: 98px;
    height: 86px;

    @for $col from 1 through 8 {
      &:nth-child(#{$col}),
      &:nth-child(#{$col + 8}) {
        left: 121px + 98 * ($col - 1) + 12.75 * ($col - 1);
      }

      &.x:nth-child(#{$col}) {
        top: 254px;
      }

      &.x:nth-child(#{$col + 8}) {
        top: 431px;
      }

      &.r:nth-child(#{$col}) {
        top: 247px;
      }

      &.r:nth-child(#{$col + 8}) {
        top: 423px;
      }

      &.g:nth-child(#{$col}) {
        top: 240px;
      }

      &.g:nth-child(#{$col + 8}) {
        top: 418px;
      }

      &.a:nth-child(#{$col}) {
        top: 236px;
      }

      &.a:nth-child(#{$col + 8}) {
        top: 413px;
      }

      &.b:nth-child(#{$col}) {
        top: 231px;
      }
      &.b:nth-child(#{$col + 8}) {
        top: 409px;
      }

      &.c:nth-child(#{$col}) {
        top: 226px;
      }

      &.c:nth-child(#{$col + 8}) {
        top: 404px;
      }

      &.d:nth-child(#{$col}) {
        top: 222px;
      }

      &.d:nth-child(#{$col + 8}) {
        top: 398px;
      }

      &.e:nth-child(#{$col}) {
        top: 217px;
      }

      &.e:nth-child(#{$col + 8}) {
        top: 394px;
      }

      &.f:nth-child(#{$col}) {
        top: 214px;
      }

      &.f:nth-child(#{$col + 8}) {
        top: 388px;
      }

      &.G:nth-child(#{$col}) {
        top: 209px;
      }

      &.G:nth-child(#{$col + 8}) {
        top: 384px;
      }

      &.A:nth-child(#{$col}) {
        top: 205px;
      }

      &.A:nth-child(#{$col + 8}) {
        top: 380px;
      }

      &.B:nth-child(#{$col}) {
        top: 200px;
      }

      &.B:nth-child(#{$col + 8}) {
        top: 375px;
      }

      &.C:nth-child(#{$col}) {
        top: 195px;
      }

      &.C:nth-child(#{$col + 8}) {
        top: 370px;
      }

      &.D:nth-child(#{$col}) {
        top: 190px;
      }

      &.D:nth-child(#{$col + 8}) {
        top: 365px;
      }

      &.E:nth-child(#{$col + 8}) {
        top: 360px;
      }

      &.E:nth-child(#{$col}) {
        top: 186px;
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1199px) {
  $note-width: 54px;
  $offset: 66px;
  $spacing: 6.5px;

  .Note {
    width: $note-width;
    height: 50px;

    @for $col from 1 through 8 {
      &:nth-child(#{$col}),
      &:nth-child(#{$col + 8}) {
        left: $offset + $note-width * ($col - 1) + $spacing * ($col - 1);
      }

      &.x:nth-child(#{$col}) {
        top: 139px;
      }

      &.x:nth-child(#{$col + 8}) {
        top: 236px;
      }

      &.r:nth-child(#{$col}) {
        top: 133px;
      }

      &.r:nth-child(#{$col + 8}) {
        top: 231px;
      }

      &.g:nth-child(#{$col}) {
        top: 130px;
      }

      &.g:nth-child(#{$col + 8}) {
        top: 228px;
      }

      &.a:nth-child(#{$col}) {
        top: 127px;
      }

      &.a:nth-child(#{$col + 8}) {
        top: 225px;
      }

      &.b:nth-child(#{$col}) {
        top: 124px;
      }
      &.b:nth-child(#{$col + 8}) {
        top: 222px;
      }

      &.c:nth-child(#{$col}) {
        top: 121px;
      }

      &.c:nth-child(#{$col + 8}) {
        top: 219px;
      }

      &.d:nth-child(#{$col}) {
        top: 119px;
      }

      &.d:nth-child(#{$col + 8}) {
        top: 216px;
      }

      &.e:nth-child(#{$col}) {
        top: 117px;
      }

      &.e:nth-child(#{$col + 8}) {
        top: 213px;
      }

      &.f:nth-child(#{$col}) {
        top: 112px;
      }

      &.f:nth-child(#{$col + 8}) {
        top: 210px;
      }

      &.G:nth-child(#{$col}) {
        top: 109px;
      }

      &.G:nth-child(#{$col + 8}) {
        top: 207px;
      }

      &.A:nth-child(#{$col}) {
        top: 112px;
      }

      &.A:nth-child(#{$col + 8}) {
        top: 204px;
      }

      &.B:nth-child(#{$col}) {
        top: 109px;
      }

      &.B:nth-child(#{$col + 8}) {
        top: 201px;
      }

      &.C:nth-child(#{$col}) {
        top: 106px;
      }

      &.C:nth-child(#{$col + 8}) {
        top: 198px;
      }

      &.D:nth-child(#{$col}) {
        top: 103px;
      }

      &.D:nth-child(#{$col + 8}) {
        top: 195px;
      }

      &.E:nth-child(#{$col}) {
        top: 101px;
      }

      &.E:nth-child(#{$col + 8}) {
        top: 192px;
      }
    }
  }
}
