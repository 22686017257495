button.Arrow {
  border-color: #f9ae11;
  border-width: 4px;
  border-radius: 100%;
  border-style: solid;
  padding: 2px !important;
  background-color: #f1f5bfff;
  background-size: 100%;
  position: absolute;
  cursor: pointer;
}

button.Arrow:disabled {
  display: none;
}

button.Arrow.up {
  top: -20px;
  right: -5px;
}

button.Arrow.down {
  left: -5px;
  bottom: -15px;
}