@font-face {
  font-family: "Fink Heavy";
  font-style: normal;
  font-weight: 400;
  src: local(FinkHeavy), url(./FinkHeavy.woff) format("woff");
}

.App {
  text-align: center;

  .logo {
    font-family: "Fink Heavy";
    font-weight: 400;
    font-style: normal;
    color: rgb(217, 234, 141);
    padding: 0.25em 0em;
    font-size: 3em;
  }
}

section.social-media {
  a:visited,
  a:link {
    color: #1da1f2;
    font-size: 24pt;
  }
}

/* Mobile */
@media only screen and (min-device-width: 320px) and (max-device-width: 1199px) {
  .App {
    .logo {
      display: none;
    }

    section.player button:nth-child(1) {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    section.player button:nth-child(2) {
      position: absolute;
      right: 20px;
      top: 60px;
    }

    section.player button:nth-child(3) {
      position: absolute;
      right: 20px;
      top: 100px;
    }
  }
}

section.picker {
  margin: 1rem auto;
}

section.player {
  .confirmation {
    color: green;
    font-size: smaller;
    display: none;

    &.visible {
      display: block;
    }
  }
}
